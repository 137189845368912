<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :finish-button-text="$t('unit.CreateUnit')"
      :next-button-text="nextBtn"
      :back-button-text="backBtn"
      class="mb-3"
      @on-complete="createUnit"
      @on-change="onUnitChange"
      :class="nextDisabled ? 'disabled-next  mb-3' : 'mb-3'"
    >
      <!-- unit creation  tab -->

      <tab-content
        :title="$t('unit.UnitCreation')"
        :before-change="validationForm"
      >
        <div class="min-height-65 p-1">
          <validation-observer ref="unitRules" tag="form">
            <UnitCreationStepOneData
              :changeInput="changeInput"
              :unit="unit"
              :startIndex="startIndex"
              @unitCoordsData="unitCoordsData"
              :isConsumer="isConsumer"
            />
          </validation-observer>
        </div>
      </tab-content>

      <!-- unit allocation tab -->
      <tab-content
        :title="$t('unit.UnitAllocation')"
        :before-change="validationFormAddress"
      >
        <div class="min-height-65 p-1 unit-allocation">
          <validation-observer ref="infoRules" tag="form">
            <UnitCreationStepOneData
              :changeInput="changeInput"
              :unit="unit"
              :disabled="disabled"
              :startIndex="startIndex"
            />
          </validation-observer>
        </div>
      </tab-content>
      <!-- address  -->
      <!-- <tab-content :title="$t('unit.Location')">
        <div class="min-height-65 p-1">
          <b-row>
            <b-col cols="12" class="mb-1">
              <h5 class="mb-0">{{ $t("unit.UnitLocation") }}</h5>
            </b-col>
            <b-col cols="12" class="mb-2">
              <h6>
                {{ $t("unit.UnitLocationDescription") }}
              </h6>
            </b-col>
            <b-col cols="12" class="mb-2">
              <div class="map-outer1">
                <location-picker
                  :isEditable="true"
                  v-model="coordinates"
                  :types="['marker']"
                  :placeholder="$t('unit.PickYourLocation')"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </tab-content> -->
      <!-- <tab-content title="Configure rules">
 <div class="min-height-65">
 <b-row>
 <b-col sm="3"></b-col>
 <b-col sm="6" class="multi-select-wh">
 <b-form-group label="Select device">
 <v-select
 id="vue-select"
 v-model="selectedDevice"
 :options="unitDevices"
 :reduce="(option) => option.id"
 @search="getUnitDevices"
 label="device_name"
 />
 </b-form-group>
 </b-col>
 </b-row>
 </div>
 </tab-content> -->
      <Loader :show="showLoading" />
    </form-wizard>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  LMap,
  LTileLayer,
  LMarker,
  LCircle,
  LPolygon,
  LIcon
} from "vue2-leaflet";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import formValidation from "@core/comp-functions/forms/form-validation";
import DeviceService from "@/libs/api/device-service";
import AccountService from "@/libs/api/account-service";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BButton,
  BAvatar
} from "bootstrap-vue";
import { required } from "@validations";
import AuthService from "@/libs/api/auth-service";
import UnitService from "@/libs/api/unit-service";
import RuleService from "@/libs/api/rule-service";
import image from "../../assets/images/pages/avatar.svg";
import UnitCreationStepOneData from "./UnitCreationStepOneData";
import Loader from "@/layouts/components/Loader.vue";
import constants from "@/utils/constants";
import DeviceSensorPreviewModal from "@/views/DeviceOnboarding/DeviceSensorPreviewModal.vue";
import moment from "moment";
import LocationPicker from "@/layouts/components/LocationPicker.vue";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    LMap,
    LTileLayer,
    LMarker,
    BCardCode,
    LCircle,
    LPolygon,
    UnitCreationStepOneData,
    Loader,
    BAvatar,
    LIcon,
    BButton,
    DeviceSensorPreviewModal,
    LocationPicker
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      coordinates: {},
      deviceSearch: null,
      ruleConfigFlag: false,
      unitId: null,
      selectedDevice: null,
      unitDevices: [],
      nextBtn: this.$t("unit.Next"),
      backBtn: this.$t("unit.Previous"),
      downloadUrl: process.env.VUE_APP_FILE_URL,
      unit: {
        device_list_object: null,
        device_list: [],
        name: null,
        status: "ACTIVE",
        labels: [],
        attached_unit_to_id: null,
        allocate_to_id:
          this.$route.query.allocate_to || localStorage.getItem("ACCOUNT_ID"),
        unit_driver_id: null,
        unit_lat: null,
        unit_long: null,
        unitIcon: null,
        start_date: null,
        end_date: null,
        file_name: "default/default-sensor.svg"
      },
      nextDisabled: true,
      startIndex: 0,
      disabled: false,
      iconSize: 40,
      showLoading: false,
      initialUnit: null,
      unitReAllocateTo: [],
      isAddSearchControl: false,
      attachedUnitTo: [],
      deviceListOption: [],
      unitDriver: [],
      statusOption: ["ACTIVE", "INACTIVE", "DAMAGED", "BLOCKED"],
      option: ["10", "20", "30"],
      unitIcon: null,
      img: image,
      required,
      refFormObserver,
      getValidationState,
      latLng: latLng(0, 0),
      zoom: 10,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159],

      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1"
      },
      isConsumer:
        localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER" ? true : false
    };
  },

  mounted() {
    if (this.$route.name === "edit-unit") {
      if (this.$route.params.id) {
        this.getOneUnit(this.$route.params.id);
      } else {
        this.$router.go(-1);
        3;
      }
    }
  },
  watch: {
    coordinates: {
      handler(newValue, oldValue) {
        this.isDisabled();
        if (
          newValue &&
          newValue.latlngs &&
          newValue.latlngs.length &&
          newValue.latlngs[0] &&
          newValue.latlngs[0].lat
        ) {
          this.latLng = [newValue.latlngs[0].lat, newValue.latlngs[0].lng];
        }
      },
      deep: true
    },
    unitIcon(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(async (value) => {
              this.unit.unitIcon = value;
            })
            .catch((e) => {});
        }
      }
    }
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  methods: {
    isDisabled() {
      if (this.startIndex == 0) {
        if (
          this.unit.name &&
          this.unit.name.length >= 3 &&
          this.unit.status &&
          this.coordinates &&
          this.coordinates.fenceType
        ) {
          this.nextDisabled = false;
        } else {
          this.nextDisabled = true;
        }
      } else if (this.startIndex == 1) {
        if (this.unit.allocate_to_id) {
          this.nextDisabled = false;
        } else {
          this.nextDisabled = true;
        }
      }
    },
    async getUnitDevices(search) {
      if (!this.unitId) {
        console.log("UnitId absent or not created properly");
        this.routeToUnitList();
        return;
      }
      let response = await new RuleService().getUnitDevices({
        unit_id: this.unitId,
        search: search || null
      });
      if (response && response.data) {
        this.unitDevices = response.data.lists;
        if (!search && this.unitDevices && this.unitDevices.length == 0) {
          this.routeToUnitList();
        }
      }
    },
    routeToUnitList() {
      this.$router.push({
        name: "unit-map"
      });
    },
    async changeInput(unit) {
      this.isDisabled();
      this.unit = unit;
    },

    async getAlldeviceList() {
      try {
        let response = await new DeviceService().deviceList({});
        if (response && response.data) {
          this.deviceListOption = response.data.devices;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    async getChildAccounts() {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true
        });

        if (response && response.data) {
          this.unitReAllocateTo = this.getAllDealersData(response.data);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    configureRule() {
      this.ruleConfigFlag = true;
      this.$router.push({
        name: "rules-config",
        query: {
          deviceId: this.selectedDevice,
          unitId: this.unitId
        }
      });
    },
    unitCoordsData(data) {
      this.coordinates = data;
    },
    DateTimeConvert(date) {
      let formattedDate = date ? date : new Date();
      return this.formattedDateTime(formattedDate, constants.DATE_TIME_FORMAT);
    },
    async createUnit() {
      try {
        if (this.unit.start_date && this.unit.end_date) {
          const startTime = moment(this.unit.start_date);
          const endTime = moment(this.unit.end_date);
          if (startTime.isSameOrAfter(endTime)) {
            throw {
              message: this.$t("unit.EndTimeGreaterThanStartTime")
            };
          }
        }
        let endDate;
        // console.log(this.unit.end_date, "this.unit.end_date");

        if (this.unit.end_date) {
          const inputDate = this.DateTimeConvert(this.unit.end_date);
          endDate = inputDate;
          // console.log(endDate, "end");
        }

        this.showLoading = true;
        // console.log(this.unit.start_date, "start");
        // console.log(this.unit.end_date, "enddate");

        let response = await new UnitService().createUnit({
          device_list:
            this.unit.device_list && this.unit.device_list.map((d) => d.id),
          name: this.unit.name,
          status: this.unit.status,
          labels: this.unit.labels,
          attached_unit_to_id: this.unit.attached_unit_to_id || undefined,
          allocate_to_id: this.unit.allocate_to_id || undefined,
          unit_driver_id: this.unit.unit_driver_id || undefined,
          unit_icon: this.unit.unit_icon || undefined,
          file_name: this.unit.file_name || undefined,
          unit_lat: this.latLng[0],
          unit_long: this.latLng[1],
          start_date: this.unit.start_date
            ? this.convertDatePerTimezone(this.unit.start_date)
            : moment(),
          end_date: endDate
            ? this.convertDatePerTimezone(endDate)
            : this.unit.end_date
        });
        this.showLoading = false;
        if (response && response.data) {
          this.unitId = response.data.unit.id;
          this.nextDisabled = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.SubmittedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$router.push({
            name: "unit-list"
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    getAllDealersData(data) {
      const result = [];
      function recursive(data) {
        result.push({ id: data.id, name: data.name });
        if (data.children.length == 0) {
          return;
        }
        for (let i = 0; i < data.children.length; i++) {
          recursive(data.children[i]);
        }
      }
      recursive(data[0]);
      return result;
    },
    async getAllUsers() {
      try {
        let response = await new AccountService().getAccountUsers({});
        if (response && response.data) {
          this.unitDriver = response.data.users.map((u) => {
            return {
              id: u.id,
              title: u.first_name + " " + u.last_name,
              value: u.id
            };
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getOneUnit(id) {
      this.showLoading = true;
      let response = await new UnitService().getOneUnit({ id });
      this.showLoading = false;
    },
    async onUnitChange(prevIndex, nextIndex) {
      this.startIndex = nextIndex;
      if (prevIndex == 1 || (prevIndex == 2 && nextIndex == 0)) {
        this.isDisabled();
        this.nextBtn = this.$t("unit.Next");
        this.backBtn = this.$t("unit.Previous");
      } else if (prevIndex == 3 && this.ruleConfigFlag == false) {
        this.routeToUnitList();
      } else if (nextIndex == 2) {
        this.nextBtn = this.$t("unit.CreateUnit");
      } else if (prevIndex == 2 && nextIndex == 3) {
        this.backBtn = this.$t("unit.SkipAndSetupLater");
        await this.createUnit();
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.vue2leaflet-map {
  &.leaflet-container {
    height: 52vh;
  }
}
.map-outer {
  width: 100%;
}
.wizard-back-unit {
  position: absolute;
  z-index: 9;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.btn-disabled {
  color: gray;
}

.disabled-next .wizard-footer-right {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
.unit-create-location {
  display: flex;
  flex-direction: column;
}
.unit-allocation {
  .unit-al {
    height: calc(100vh - 376px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .unit-creation-scroll {
    height: auto !important;
  }
  @media only screen and (min-width: 1024px) {
    .daterangepicker.openscenter {
      left: 193px !important;
    }
  }
}
</style>
