<template>
  <div>
    <b-modal
      id="open-device-sensor-preview-popup"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      modal-class="no-header-modal  preview-sensors "
      :hide-header="true"
    >
      <div style="padding: 0.8rem 1.4rem"></div>

      <div class="new-invite-modal-title">
        <b>{{ $t("device.Sensors") }}</b>
      </div>
      <DeviceSensor
        :deviceData="deviceData"
        :activeTripDetector="activeTripDetector"
        :activeFuelTheft="activeFuelTheft"
        :isViewOnly="isViewOnly"
        :DeviceSensorFun="DeviceSensorFun"
        :saveSensors="saveSensors"
        protocolName=""
        :unit="unit"
        :isEditable="
          checkAbility({
            action: constants.PERMISSIONS_ACTION.CARD_ORDERING,
            subject: constants.PERMISSIONS_MODEL.DEVICES
          })
        "
        :activeTab="false"
        isPreview="true"
      ></DeviceSensor>
      <div class="d-flex justify-content-center mb-64 mtt-50">
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          :disabled="!isSave"
          @click="saveSensors"
        >
          {{ $t("user.Save") }}
        </b-button> -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="mt-2"
          @click="clearUnitInfo"
        >
          {{ $t("Notification.Close") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, VBTooltip, BModal } from "bootstrap-vue";
import DeviceSensor from "@/views/DeviceOnboarding/DeviceSensor.vue";
import UnitInfoMenu from "@/views/Unit/UnitTracking/shared/UnitInfoMenu.vue";
import DeviceService from "@/libs/api/device-service";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BModal,
    BButton,
    UnitInfoMenu,
    DeviceSensor
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },

  data() {
    return {
      isViewOnly: false,
      isEditable: true,
      deviceData: {},
      DeviceSensorData: [],
      isSave: false
    };
  },
  props: ["device", "closeSensorsModal", "isShowSensors", "unit"],
  mounted() {
    if (this.device && this.device.id) {
      this.getOneDevice(this.device.id);
    }
  },
  watch: {
    device() {
      if (this.device && this.device.id) {
        this.getOneDevice(this.device.id);
      }
    },
    isShowSensors(val) {
      if (val) {
        if (this.device && this.device.id) {
          this.getOneDevice(this.device.id);
        }
      }
    }
  },
  computed: {},
  methods: {
    showUnitInfo() {
      this.$bvModal.show("open-device-sensor-preview-popup");
    },
    clearUnitInfo() {
      this.closeSensorsModal();
      this.$bvModal.hide("open-device-sensor-preview-popup");
    },
    activeTripDetector() {},
    activeFuelTheft() {},
    async DeviceSensorFun(sensors) {
      this.DeviceSensorData = sensors;
      const resp = await this.saveSensors();
      this.isSave = true;
      return resp;
    },

    async getOneDevice(id) {
      try {
        if (!id) {
          return true;
        }

        let response = await new DeviceService().getOneDevice({ id });
        this.showLoading = false;
        if (response && response.data) {
          this.deviceData = {
            deviceId: id,
            id,
            sensors:
              response.data.sensors && response.data.sensors.length
                ? response.data.sensors
                : []
          };
          this.DeviceSensorData =
            response.data.sensors && response.data.sensors.length
              ? response.data.sensors
              : [];
          this.showUnitInfo();
          this.isSave = false;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async saveSensors() {
      try {
        const sensors = this.DeviceSensorData.map((s) => {
          const sensorsObj = {
            description: s.description,
            fields: s.fields,
            name: s.name,
            type: s.type,
            visible: s.visible
          };
          return sensorsObj;
        });

        let response = await new DeviceService().updateDeviceParametersReOrder({
          id: this.device.id,
          sensors: { _arr: sensors }
        });

        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("device.SensorOrdering"),
              text: "",
              icon: "InfoIcon",
              variant: "success"
            }
          });
          //  this.clearUnitInfo();

          return true;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
      } catch (err) {
        this.showLoading = false;

        this.isReset = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return false;
      }
    }
  }
};
</script>

<style lang="scss">
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}
</style>
