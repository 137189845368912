<template>
  <div>
    <div @click="closeModal(true)" class="d-flex">
      <div class="location-input">
        <span v-if="!displayData" class="placeholder-text p-1">
          Pick your location</span
        >
        <span v-else>{{ displayData }}</span>
      </div>

      <!-- <b-input-group-append>
            <b-input-group-text>
              <LocationIcon />
            </b-input-group-text>
          </b-input-group-append> -->
      <div class="location-input-icon"><LocationIcon /></div>
    </div>

    <b-modal
      id="modal-Modify"
      hide-footer
      centered
      size="lg"
      modal-class="map-view-model"
      v-model="showModal"
      @hidden="closeModal(false)"
    >
      <div class="set-map-top">
        <MapPopView
          v-model="dataValue"
          :setDisplayInfo="setDisplayInfo"
          :class="!isEditable ? 'disabled' : ''"
          :isEditable="isEditable"
          :types="types"
          :icon="icon"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import MapPopView from "@/layouts/components/MapPopView.vue";
import LocationIcon from "@/assets/images/new-icon/LocationIcon.svg";
import {
  BFormInput,
  BFormGroup,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BInputGroupText,
  BRow,
  BCol
} from "bootstrap-vue";

export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    isEditable: Boolean,
    types: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  components: {
    BInputGroupAppend,
    BFormInput,
    BButton,
    LocationIcon,
    BFormGroup,
    BInputGroup,
    MapPopView,
    BInputGroupText,
    BRow,
    BCol
  },
  data() {
    return {
      dataValue: {},
      showModal: false,
      displayData: ""
    };
  },
  mounted() {
    this.dataValue = this.value;
    this.updateShapeInfo();
  },

  watch: {
    dataValue: {
      handler(newValue, oldValue) {
        if (newValue) {
          this.$emit("input", newValue);
          this.updateShapeInfo();
        }
      },
      deep: true
    },
    value(newValue) {
      if (newValue) {
        this.dataValue = newValue;
        this.updateShapeInfo();
      }
    }
  },
  methods: {
    closeModal(flag) {
      this.showModal = flag;
    },
    setDisplayInfo(displayInfo) {
      this.displayData = displayInfo;
      this.closeModal(false);
    },
    updateShapeInfo() {
      if (this.dataValue && this.dataValue.fenceType) {
        this.displayData = `Type: ${this.dataValue.fenceType}`;
        if (
          this.dataValue.latlngs &&
          (this.dataValue.latlngs.length || this.dataValue.latlngs.lat)
        )
          this.displayData += `Data : ${JSON.stringify(
            this.dataValue.latlngs
          )}`;
      } else {
        this.displayData = "";
      }
    }
  }
};
</script>

<style lang="scss">
.dark-layout {
  .location-input {
    border: 1px solid var(--darkgray);
    background-color: var(--darkgray);
  }
  .location-input-icon {
    height: 2.6rem;
    border-bottom: 1px solid var(--darkgray);
    border-right: 1px solid var(--darkgray);
    border-top: 1px solid var(--darkgray);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
  }
}
.location-input-icon {
  height: 2.6rem;
  border-bottom: 1px solid var(--gray-605);
  border-right: 1px solid var(--gray-605);
  border-top: 1px solid var(--gray-605);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 5px 5px 0px;
  width: 10%;
}
.location-input {
  height: 2.6rem;
  border: 1px solid var(--gray-605);
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  background-color: var(--gray-300);
  width: 90%;
  // width: 70%;
  display: flex;
  align-items: center;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
  }
}
.modal .modal-header .close {
  transform: translate(8px, -2px);
  z-index: 9;
}

.map-view-model {
  .modal-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }
  .map-modal-data {
    padding-left: 15px;
    padding-right: 15px;
  }
  .map-view-model {
    .modal-header {
      position: relative;
      z-index: 9;
      background-color: transparent !important;
      // width: 20px;
      margin-left: auto;
    }
  }
  .leaflet-right .leaflet-control {
    margin-right: 30px;
  }
  .leaflet-control-geosearch .results {
    color: var(--dark);
  }
}
.modal-1000 {
  // width: calc(100vw - 300px);
  // max-width: calc(100vw - 300px);
}

.set-map-top {
  position: relative;
  z-index: 2;
  margin-top: -33px;
}
@media (max-width: 991px) {
  .set-map-top {
    margin-top: 0px;
  }
}
</style>
