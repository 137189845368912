<template>
  <div>
    <div style="position: relative">
      <b-form-group :label="$t('unit.SelectIcon')">
        <b-input-group
          @click="clickTxt()"
          :class="$route.name === 'view-unit' ? 'disabledUnitIcon' : ''"
        >
          <b-input-group-prepend class="input-prefix-img">
            <b-img square :src="img_url" variant="outline-primary" size="30" />
          </b-input-group-prepend>
          <b-form-input
            v-b-toggle.collapse-1
            v-model="icon_label"
            :placeholder="$t('unit.SelectIcon')"
            @input="(e) => getIcon(e)"
            :class="
              visible ? 'unit-icon-input collapsed-icons' : 'unit-icon-input'
            "
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="collapse-4"
          ></b-form-input>
        </b-input-group>

        <b-collapse id="collapse-4" v-model="visible" class="unit-icon-modal">
          <b-card class="unit-icon-card">
            <div
              v-for="icon in icons"
              :key="icon.path"
              class=""
              style="float: left"
            >
              <div class="avtar" style="padding: 7px">
                <span @click="selectIcon(icon)" class="curserPointer">
                  <b-avatar
                    circle
                    :src="icon.url"
                    variant="light-primary"
                    size="50"
                    v-b-tooltip.hover.v-primary
                    :title="icon.label"
                    class="unit-image"
                  />
                </span>
              </div>
            </div>

            <!--/ media -->

            <!-- form -->
          </b-card>
        </b-collapse>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import UnitService from "@/libs/api/unit-service";
import {
  BCard,
  BCardBody,
  BCollapse,
  BButton,
  VBToggle,
  BFormInput,
  BImg,
  BAvatar,
  BCol,
  BMedia,
  VBTooltip,
  BInputGroupPrepend,
  BInputGroup,
  BFormGroup
} from "bootstrap-vue";

// Note: Vue automatically prefixes the directive name with 'v-'

export default {
  name: "iconView",
  components: {
    BCard,
    BCardBody,
    BCollapse,
    BButton,
    VBToggle,
    BFormInput,
    BImg,
    BAvatar,
    BCol,
    BMedia,
    VBTooltip,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup
  },
  props: ["imgFilePath", "selectedIcon"],

  data() {
    return {
      test: "",
      icon_path: "",
      icon_label: "",
      img_url: require("@/assets/images/new-icon/default-sensor.svg"),
      visible: false,
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75
      },
      oldIcons: [],
      icons: []
    };
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip
  },
  mounted() {
    this;
    this.getAllIcons();
  },
  watch: {
    imgFilePath(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatedIcons();
      }
    }
  },
  computed: {},
  methods: {
    async getAllIcons() {
      const response = await new UnitService().getAllIcons();
      this.icons = (response && response.data && response.data.unitIcon) || [];
      this.oldIcons = this.icons;
      this.img_url = response && response.data && response.data.defaultIcon;
      this.updatedIcons();
    },
    async updatedIcons() {
      if (this.imgFilePath && this.imgFilePath.trim()) {
        this.icons = this.oldIcons.filter(
          (item) =>
            item.path.toLowerCase() === this.imgFilePath.trim().toLowerCase()
        );
        this.img_url =
          this.icons && this.icons.length ? this.icons[0].url : this.img_url;
        this.icon_path =
          this.icons && this.icons.length ? this.icons[0].path : "";
        this.icon_label =
          this.icons && this.icons.length ? this.icons[0].label : "";
      }
    },
    getIcon(e) {
      if (e === "" || !e || (e && !e.trim())) {
        this.icons = this.oldIcons; // lowecase
      } else {
        e = e.trim();
        const search = new RegExp(e, "i"); // prepare a regex object
        this.icons = this.oldIcons.filter((item) => search.test(item.label));
      }
      this.visible = true;
    },
    selectIcon(icon) {
      this.visible = false;
      this.icon_path = icon.path;
      this.icon_label = icon.label;
      this.img_url = icon.url;
      this.selectedIcon(icon);
    },
    clickTxt() {
      this.visible = this.visible ? false : true;
      this.icons = this.oldIcons;
      // if (this.icon_label && this.icon_label.trim()) {
      //   const search = new RegExp(this.icon_label.trim(), "i"); // prepare a regex object
      //   this.icons = this.oldIcons.filter((item) => search.test(item.label));
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.unit-icon-card {
  background-color: var(--white);
  z-index: 9;
  height: 32vh;
  overflow: auto;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.input-prefix-img {
  border: 1px solid var(--gray4);
  height: 37.5px;
  width: 40px;
  border-radius: 7px 0px 0px 7px;
  text-align: center;
  img {
    height: 32px;
    margin: auto;
    padding: 3px;
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
.unit-icon-modal {
  position: absolute;
  z-index: 9;
  padding: $px_10;
  min-width: $percent_100;
  min-height: 32vh;
  padding: 5px 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin: 0;
}
.unit-image {
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 8px;
      border-radius: initial;
    }
  }
}
</style>
