<template>
  <div>
    <l-map ref="map" style="height: 400px" />
    <div class="d-flex mt-2 map-modal-data">
      <b-form-input
        :placeholder="placeholder"
        disabled
        v-model="displayData"
      ></b-form-input>
      <b-button
        variant="primary"
        class="ml-1"
        @click="save(data1, layerType)"
        :disabled="!mapData || !isEditable || buttonDisable"
        >{{ $t("Save") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap } from "vue2-leaflet";
import MapLayers from "@/libs/map/MapLayers";
import { BFormInput, BButton } from "bootstrap-vue";
import "@/libs/map/js/turf";
import "@/libs/map/js/draw";
import L, { latLng, marker } from "leaflet";
import "@/libs/map/css/leaflet.draw.css";
import "@/libs/map/css/leaflet.trace.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";
import { GeoSearchControl } from "leaflet-geosearch";
import * as Utils from "@/libs/utils";
export default {
  props: {
    value: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    types: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: ""
    },
    setDisplayInfo: {
      type: Function
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  components: {
    LMap,
    BFormInput,
    BButton
  },
  data() {
    return {
      latLng: [51.505, -0.09],
      map: null,
      data1: [],

      layerType: "",
      displayData: "",

      e: null,
      radius: 0,
      mapData: {},
      buttonDisable: false
    };
  },
  mounted() {
    this.createMap();
  },

  watch: {
    value(newValue) {
      if (newValue) {
        this.createMap();
      }
    }
  },

  methods: {
    save(data, layerType) {
      this.updateShapeInfo();

      this.drawer();
    },

    drawer(e) {
      if (!this.mapData) {
        this.mapData = {};
      }

      // Reset displayData
      this.displayData = "";

      if (this.mapData && this.mapData.fenceType) {
        this.map.eachLayer((layer) => {
          if (!!layer.toGeoJSON) {
            this.map.removeLayer(layer);
          }
        });

        this.$emit("input", this.mapData);
        this.setDisplayInfo(this.displayData);
      } else {
      }
    },
    saveShapeInfo() {
      this.$emit("shapeInfoSaved", this.shapeInfo);
    },
    invalidateSize() {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 0);
    },
    fitBounds(bounds) {
      setTimeout(() => {
        this.map.fitBounds(bounds);
      }, 400);
    },
    createMap() {
      const onDrawCreated = (event) => {
        this.e = event;
        this.map.eachLayer((layer) => {
          if (
            layer instanceof L.Polyline ||
            layer instanceof L.Marker ||
            layer instanceof L.Circle
          ) {
            layer.remove();
          }
        });

        this.mapData = { fenceType: event.layerType };

        const layer = event.layer;
        this.mapData.fenceType = event.layerType;
        if (layer instanceof L.Polyline) {
          drawnItems.addLayer(layer);
          this.mapData.latlngs = layer._latlngs;
        } else if (layer instanceof L.Circle) {
          this.mapData.latlngs = latLng(layer._latlng);

          this.mapData.center = latLng(layer._latlng);
          this.mapData.radius = layer._mRadius;
          drawnItems.addLayer(layer);
        } else if (layer instanceof L.Marker) {
          this.mapData.latlngs = [latLng(layer._latlng)];
          drawnItems.addLayer(layer);
        }
        this.updateShapeInfo();
      };
      const onDrawEdited = (event) => {
        this.buttonDisable = false;
        this.e = event;
        if (this.map) {
          const layers = event.layers;

          layers.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              this.mapData.latlngs = [layer._latlng];
            } else if (
              layer instanceof L.Rectangle ||
              layer instanceof L.Polygon ||
              layer instanceof L.Polyline
            ) {
              this.mapData.latlngs = layer._latlngs;
            } else if (layer instanceof L.Circle) {
              this.mapData.center = latLng(layer._latlng);
              this.mapData.latlngs = latLng(layer._latlng);
              this.mapData.radius = layer._mRadius;
            }

            this.updateShapeInfo();
          });
        }
      };

      const onDrawDeleated = () => {
        this.mapData = null;
        this.displayData = "";
      };
      const onDrawStarted = () => {
        this.buttonDisable = true;
      };

      Utils.getUsersCurrentLocation()
        .then((position) => {
          const location = new L.LatLng(position[0], position[1]);
          this.map.fitBounds(L.latLngBounds(location, location), {
            maxZoom: 14
          });
        })
        .catch((err) => {
          console.error(
            "User rejected location permission or browser does not support",
            err
          );
        });

      this.map = this.$refs && this.$refs.map && this.$refs.map.mapObject;

      new MapLayers(this.map);
      const drawnItems = new L.FeatureGroup();
      this.map.addLayer(drawnItems);

      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider,
        autoComplete: true,
        autoCompleteDelay: 250,
        showMarker: true
      });
      this.map.addControl(searchControl);
      this.map.on("geosearch/showlocation", this.geosearch);

      const drawControlOptions = {
        trace: true,
        draw: {
          marker: {
            shapeOptions: {
              color: "blue"
            }
          },
          polyline: {
            shapeOptions: {
              color: "blue"
            }
          },
          polygon: {
            shapeOptions: {
              color: "blue"
            }
          },
          circle: {
            shapeOptions: {
              color: "blue"
            }
          },
          rectangle: {
            shapeOptions: {
              color: "blue"
            }
          }
        },
        edit: {
          featureGroup: drawnItems
        }
      };

      let map_types = { draw: {} };

      ["marker", "polyline", "polygon", "circle", "rectangle"].forEach(
        (element) => {
          if (
            this.isEditable &&
            drawControlOptions.draw[element] &&
            this.types.indexOf(element) >= 0
          ) {
            map_types.draw[element] = drawControlOptions.draw[element];
          } else {
            map_types.draw[element] = false;
          }
        }
      );
      if (map_types) {
        if (this.isEditable) {
          map_types["edit"] = {
            featureGroup: drawnItems
          };
        }
        const drawControl = new L.Control.Draw(map_types);
        this.map.on("draw:created", onDrawCreated);
        this.map.on("draw:edited", onDrawEdited);
        this.map.on("draw:deleted", onDrawDeleated);
        this.map.on("draw:editstart", onDrawStarted);
        this.map.addControl(drawControl);
      }

      if (this.value && this.value.fenceType) {
        this.e = this.value;
        this.mapData = JSON.parse(JSON.stringify(this.value));

        this.updateShapeInfo();
        if (
          this.value.fenceType === "circle" &&
          this.value.center &&
          this.value.center.lat
        ) {
          const center = latLng([this.value.center.lat, this.value.center.lng]);
          center.radius = this.value.radius;

          const circle = L.circle(center, {
            radius: this.value.radius,
            color: "blue"
          }).addTo(this.map);
          drawnItems.addLayer(circle);
          const bounds = circle.getBounds();
          this.fitBounds(bounds);
        } else if (
          this.value.fenceType === "polygon" &&
          this.value.latlngs.length
        ) {
          const polygon = L.polygon(this.value.latlngs, {
            color: "blue"
          }).addTo(this.map);
          drawnItems.addLayer(polygon);
          const bounds = polygon.getBounds();
          this.fitBounds(bounds);
        } else if (
          this.value.fenceType === "rectangle" &&
          this.value.latlngs.length
        ) {
          const rectangle = L.rectangle(this.value.latlngs, {
            color: "blue"
          }).addTo(this.map);
          drawnItems.addLayer(rectangle);
          const bounds = rectangle.getBounds();
          this.fitBounds(bounds);
        } else if (
          this.value.fenceType === "marker" &&
          this.value.latlngs &&
          this.value.latlngs.length
        ) {
          let marker;
          if (this.icon) {
            const customIcon = L.icon({
              iconUrl: this.icon,
              iconSize: [40, 40]
              // iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
              // popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
            });

            marker = L.marker(
              [this.value.latlngs[0].lat, this.value.latlngs[0].lng],
              { icon: customIcon } // Use the custom icon here
            ).addTo(this.map);
          } else {
            marker = L.marker(
              [this.value.latlngs[0].lat, this.value.latlngs[0].lng],
              { color: "blue" }
            ).addTo(this.map);
          }
          drawnItems.addLayer(marker);
          const location = new L.LatLng(
            this.value.latlngs[0].lat,
            this.value.latlngs[0].lng
          );

          const bounds = L.latLngBounds(location, location);
          this.fitBounds(bounds);
        }
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.latLng = [
                position.coords.latitude,
                position.coords.longitude
              ];
              this.map.setView(
                [position.coords.latitude, position.coords.longitude],
                13
              );
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
      this.invalidateSize();
    },
    geosearch(e) {
      this.latLng = [e.marker._latlng.lat, e.marker._latlng.lng];
    },
    updateShapeInfo(newData) {
      if (this.mapData && this.mapData.fenceType) {
        this.displayData = `Type: ${this.mapData.fenceType}`;
        if (this.mapData.latlngs) {
          this.displayData += `, Data: ${JSON.stringify(this.mapData.latlngs)}`;
        } else if (this.mapData.center) {
          this.displayData += `, Data: ${JSON.stringify(this.mapData.center)}`;
        }
      } else {
        this.displayData = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 400px;
}
.vue2leaflet-map {
  &.leaflet-container {
    height: 600px;
    height: calc(100vh - 107px);
  }
}
.map-outer {
  width: 100%;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup {
  bottom: 17px !important;
}
.input-wrapper {
  margin-top: 10px;
}
.input-wrapper input {
  width: 70%;
  padding: 5px;
  margin-right: 10px;
}
.input-wrapper button {
  padding: 5px 10px;
}
@media screen and (max-width: 991px) {
  .vue2leaflet-map {
    &.leaflet-container {
      height: calc(100vh - 300px);
    }
  }
}
</style>
