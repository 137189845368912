<template>
  <div class="unit-creation-page">
    <b-row>
      <b-col
        cols="12"
        class="mb-2 d-flex justify-content-between align-items-center"
        v-if="startIndex == 1"
      >
        <h5 class="mb-0">{{ $t("unit.UnitAllocation") }}</h5>
        <div class="text-right d-flex">
          <DeviceSensorPreviewModal
            v-if="unit && unit.device_list && unit.device_list.length"
            class="mr-1"
            :devices="
              unit && unit.device_list && unit.device_list.map((u) => u.id)
            "
            :unitName="unit && unit.name"
            size="sm"
          ></DeviceSensorPreviewModal>
          <b-button
            variant="outline-primary"
            size="sm"
            @click="$router.go(-1)"
            class="wizard-back-unit-remove v2-back"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div class="unit-al">
      <b-row>
        <b-col md="6" v-if="startIndex == 1">
          <validation-provider
            #default="validationContext"
            name="unit allocate to"
            rules="required"
          >
            <b-form-group
              :label="
                startIndex == 1
                  ? $t('unit.UnitAllocateTo')
                  : $t('unit.UnitReAllocateTo')
              "
              :state="getValidationState(validationContext)"
            >
              <treeselect
                v-model="allocate_to_id"
                :multiple="false"
                :options="unitReAllocateTo"
                :searchable="true"
                ref="treeselect"
                :clearable="false"
                @open="handleMenuOpen"
                @select="handleTreeSelect"
                @search-change="searchAccount"
                class="q456"
              >
                <div
                  slot="option-label"
                  class="tree-select-opction"
                  slot-scope="{ node }"
                  :title="node.label"
                >
                  {{ node.label }}
                </div>
              </treeselect>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6" v-if="startIndex == 1">
          <b-form-group :label="$t('unit.UnitOperator')">
            <v-select
              id="vue-select"
              :placeholder="$t('unit.SelectUnitOperator')"
              v-model="unit_driver_id"
              :options="unitDriver"
              :reduce="(option) => option.id"
              label="title"
              :disabled="isEnabled == true"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="startIndex == 1" class="new-date-picker-25">
          <b-form-group :label="$t('operators.StartDate')" class="date-mb">
            <validation-provider
              #default="{ errors }"
              name="Start date"
              rules="required"
            >
              <DateTimePicker
                v-model="start_date"
                :isEditable="true"
                :placeholder="$t('unit.EnterStartDate')"
                position="left"
                :isMinDate="true"
              />
              <!-- <flat-pickr
                v-model="start_date"
                :isEditable="true"
                :placeholder="$t('unit.EnterStartDate')"
                :config="{
                  enableTime: true,
                  dateFormat: 'DD/MM/YYYY HH:mm',
                  minDate: 'today',
                  defaultDate: new Date(),
                  minTime: minTime
                }"
                :class="
                  errors.length == 0 && start_date ? 'is-valid' : 'is-invalid'
                "
                :state="errors.length > 0 ? false : null"
              />
              <div class="flat-picker-icon">
                <a
                  ><feather-icon icon="XIcon" size="24" @click="clearStartDate"
                /></a>
              </div> -->

              <small class="text-danger">{{
                errors[0] ? handleError(errors[0]) : ""
              }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="startIndex == 1" class="new-date-picker-25">
          <b-form-group :label="$t('unit.EndDate')" class="date-mb">
            <DateTimePicker
              v-model="end_date"
              :isEditable="true"
              :isMinDate="true"
              :placeholder="$t('unit.EnterEndDate')"
              position="right"
            />
            <!-- <flat-pickr
              v-model="end_date"
              :isEditable="true"
              :placeholder="$t('unit.EnterEndDate')"
              :config="{
                enableTime: true,
                dateFormat: 'DD/MM/YYYY HH:mm',
                minDate: endDateDisabled ? endDateDisabled : 'today',
                minTime: minTime
              }"
            /> -->
            <!-- <div class="flat-picker-icon">
              <a
                ><feather-icon icon="XIcon" size="24" @click="clearEndDate"
              /></a>
            </div> -->
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mb-2 d-flex justify-content-between align-items-center"
          v-if="startIndex == 0"
        >
          <h5 class="mb-0">{{ $t("unit.UnitsTitle") }}</h5>
          <div class="text-right d-flex">
            <DeviceSensorPreviewModal
              v-if="unit && unit.device_list && unit.device_list.length"
              class="mr-1"
              :devices="
                unit && unit.device_list && unit.device_list.map((u) => u.id)
              "
              :unitName="unit && unit.name"
              size="sm"
            ></DeviceSensorPreviewModal>
            <b-button
              variant="outline-primary"
              size="sm"
              @click="$router.go(-1)"
              class="wizard-back-unit-remove v2-back"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <div>
        <div
          class=""
          :class="
            $route.name === 'unit-creation'
              ? 'unit-creation-scroll'
              : 'unit-create-scroll'
          "
        >
          <b-row>
            <b-col
              cols="1"
              v-if="
                isEnabled == true &&
                $route.name !== 'view-unit' &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.UNITS
                })
              "
            >
              <feather-icon
                icon="EditIcon"
                class="text-primary action-icon"
                size="18"
                @click="editUnit()"
            /></b-col>

            <b-col
              md="6"
              v-if="startIndex == 0 || isEnabled == true || isEnabled == false"
            >
              <validation-provider
                #default="validationContext"
                name="unit name"
                :rules="{
                  required: true,
                  min: constants.MIN_UNIT_NAME,
                  max: constants.MAX_UNIT_NAME
                }"
              >
                <b-form-group :label="$t('unit.UnitName*')">
                  <b-form-input
                    :placeholder="$t('unit.EnterUnitName')"
                    v-model="name"
                    @input="
                      changeInput({
                        ...unit,
                        name: name
                      })
                    "
                    :state="getValidationState(validationContext)"
                    :disabled="startIndex == 1 || isEnabled == true"
                  />
                  <b-form-invalid-feedback>
                    {{
                      validationContext.errors[0]
                        ? handleError(validationContext.errors[0])
                        : ""
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              v-if="startIndex == 0 || isEnabled == true || isEnabled == false"
            >
              <validation-provider
                #default="validationContext"
                name="status"
                rules="required"
              >
                <b-form-group
                  :label="$t('unit.Status*')"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    id="vue-select"
                    :placeholder="$t('unit.SelectStatus')"
                    v-model="status"
                    :options="statusOption"
                    :reduce="(option) => option.key"
                    label="label"
                    :disabled="startIndex == 1 || isEnabled == true"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{
                      validationContext.errors[0]
                        ? handleError(validationContext.errors[0])
                        : ""
                    }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              class="iconbottom"
              v-if="startIndex == 0 || isEnabled == false || isEnabled == true"
              ><span
                v-if="unit && unit.file_name"
                :class="isEnabled ? 'disabled' : ''"
              >
                <iconView
                  :imgFilePath="unit && unit.file_name ? unit.file_name : ''"
                  :selectedIcon="selectedIcon"
                ></iconView>
              </span>
            </b-col>
            <!-- <b-col md="6" v-if="startIndex == 0 || isEnabled == false">
 <b-form-group :label="$t('unit.UnitIcon')">
 <b-form-file
 v-model="unitIcon"
 placeholder="Choose a file or drop it here..."
 drop-placeholder="Drop file here..."
 @change="onFileSelect"
 />
 </b-form-group>

 <b-modal
 v-model="modalShow"
 centered
 size="lg"
 modal-class="no-header-modal  "
 >
 <VueCropper
 :aspectRatio="1 / 1"
 :autoCropArea="0.65"
 :restore="false"
 :guides="false"
 :center="false"
 :highlight="false"
 :cropBoxMovable="true"
 :cropBoxResizable="false"
 :toggleDragModeOnDblclick="false"
 v-show="selectedFile"
 ref="cropper"
 :src="selectedFile"
 alt="Source Image"
 class="cropper-adjust"
 ></VueCropper>
 <b-button
 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
 variant="primary"
 class="mt-2 mr-1"
 @click="saveImage()"
 >Crop</b-button
 >
 <b-button
 v-ripple.400="'rgba(186, 191, 199, 0.15)'"
 variant="outline-secondary"
 class="mt-2"
 @click="cancelImage()"
 text
 >Cancel</b-button
 >
 </b-modal>
 </b-col> -->
            <b-col
              md="6"
              v-if="
                (startIndex == 0 || isEnabled == false || isEnabled == true) &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.READ,
                  subject: constants.PERMISSIONS_MODEL.LABELS
                })
              "
            >
              <b-form-group
                :label="$t('unit.Labels')"
                :class="isEnabled ? 'disabled' : ''"
              >
                <TagMultiSelect v-model="labels" />
              </b-form-group>
            </b-col>
            <b-col lg="6" sm="12" class="mb-2" v-if="startIndex !== 1">
              <div class="mb-2">
                <h5 class="mb-1">{{ $t("unit.UnitLocation") }}*</h5>
                <h6>
                  {{ $t("unit.UnitLocationDescription") }}
                </h6>
              </div>
              <div class="map-outer1">
                <location-picker
                  v-if="$route.name == 'unit-creation'"
                  :isEditable="true"
                  v-model="coordinates"
                  :types="['marker']"
                  :placeholder="$t('unit.PickYourLocation')"
                />
                <location-picker
                  v-if="$route.name !== 'unit-creation'"
                  :isEditable="false"
                  v-model="coordinates"
                  :types="[]"
                  :icon="file_name_url"
                />
              </div>
            </b-col>
          </b-row>

          <b-row v-if="startIndex == 1 || startIndex == null" class="card-view">
            <draggable
              v-model="device_list"
              tag="div"
              handle=".draggable-task-handle"
              style="width: 100%"
              class="d-flex flex-wrap"
            >
              <b-col
                cols="4"
                v-for="item in device_list"
                :key="item.id"
                :class="isEnabled ? '' : 'draggable-task-handle d-inline'"
              >
                <b-card class="cust-card dark-bg-card unit-card-p">
                  <div class="card-headder-top">
                    <div class="device-version-model">
                      <div class="device-name">{{ item.name }}</div>
                      <div class="modal-version">{{ item.model_name }}</div>
                    </div>

                    <b-spinner
                      variant="primary"
                      v-show="showLoadingClick"
                      label="Text Centered"
                    ></b-spinner>

                    <div
                      class="drop-dwn"
                      v-if="
                        startIndex == 0 || startIndex == 1 || isEnabled == false
                      "
                    >
                      <b-dropdown
                        variant="link"
                        toggle-class="p-0"
                        no-caret
                        :disabled="showLoadingClick"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-left text-body"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="
                            (startIndex == 0 ||
                              startIndex == 1 ||
                              isEnabled == false) &&
                            item.personal_device &&
                            checkAbility({
                              action: constants.PERMISSIONS_ACTION.UPDATE,
                              subject: constants.PERMISSIONS_MODEL.DEVICES
                            })
                          "
                          :to="{ name: 'edit-device', params: { id: item.id } }"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">{{
                            $t("unit.Edit")
                          }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            startIndex == 0 ||
                            startIndex == 1 ||
                            isEnabled == false
                          "
                          @click="removeDevice(item.id)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">{{
                            $t("unit.RemoveFromUnit")
                          }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="
                            (startIndex == 0 ||
                              startIndex == 1 ||
                              isEnabled == false) &&
                            checkAbility({
                              action:
                                constants.PERMISSIONS_ACTION.CARD_ORDERING,
                              subject: constants.PERMISSIONS_MODEL.DEVICES
                            })
                          "
                          @click="devicePreview(item)"
                        >
                          <span
                            v-b-tooltip.hover.v-primary
                            :title="$t('device.SensorsPreviewTool')"
                          >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">{{
                              $t("device.SensorsPreview")
                            }}</span>
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="card-number">{{ item.identifier }}</div>
                  <div class="sts-btm">
                    <div class="last-report" v-if="item.message_time">
                      <feather-icon icon="ClockIcon" size="8" />
                      {{ $t("unit.LastReportedOn") }}
                      {{ changeDate(item.message_time) }}
                    </div>
                    <div class="last-report" v-else></div>
                    <div>
                      <b-badge
                        class="badge-cust"
                        :variant="resolveFilterBadgeColor(item.status)"
                      >
                        {{
                          `${
                            item.status
                              ? $t(`unit.StatusList.${item.status}`)
                              : " "
                          }`
                        }}
                      </b-badge>
                    </div>
                  </div>
                </b-card>
              </b-col>

              <b-col cols="4" v-if="!isEnabled">
                <b-card class="cust-card dark-bg-card">
                  <div class="add-new" @click="showFun">
                    <div class="text-primary cust-clcik" v-if="startIndex == 1">
                      {{ $t("unit.ClickHereToAddDevice") }}
                    </div>
                    <div class="text-primary cust-clcik" v-else>
                      {{ $t("unit.ClickHereToAddMoreDevice") }}
                    </div>
                    <div>
                      <feather-icon
                        class="text-primary"
                        icon="PlusCircleIcon"
                        size="30"
                      />
                    </div>
                  </div>
                </b-card>
              </b-col>
            </draggable>
          </b-row>
        </div>
        <b-row v-if="isEnabled == true || isEnabled == false">
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              variant="outline-primary"
              class="mt-2 mr-1 submitbtn"
              v-if="
                isConsumer == false &&
                $route.name !== 'view-unit' &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.UNITS
                })
              "
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="showAllocation"
              >{{ $t("unit.UnitAllocation") }}</b-button
            >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1 submitbtn"
              type="submit"
              v-if="
                $route.name !== 'view-unit' &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.UNITS
                })
              "
              :disabled="isEnabled == true"
              @click="updateUnit(id, $event)"
            >
              {{ $t("device.Save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              v-if="
                $route.name !== 'view-unit' &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.UPDATE,
                  subject: constants.PERMISSIONS_MODEL.UNITS
                })
              "
              class="mt-2 submitbtn"
              :disabled="isEnabled == true"
              @click="resetForm"
            >
              {{ $t("device.Discard") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-modal
        v-model="deviceListModalShow"
        centered
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        :hide-header="true"
        modal-class="no-header-modal device-h"
      >
        <b-card-text>
          <h4 class="text-center">{{ $t("device.deviceallocation") }}</h4>
          <b-form-group
            :label="$t('unit.DeviceList')"
            :disabled="startIndex == 0 || isEnabled == true"
          >
            <v-select
              id="vue-select"
              :placeholder="$t('unit.SelectDeviceList')"
              v-model="device_list_object"
              :options="deviceListOption"
              :reduce="(option) => option.id"
              @search="search"
              @input="changeInputVSelect"
              label="device_name"
            />
          </b-form-group>

          <div class="d-flex justify-content-center mb-64 mtt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="saveDeviceList()"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-2"
              @click="onCloseDeviceModal()"
            >
              {{ $t("user.Cancel") }}
            </b-button>
          </div>
        </b-card-text>
      </b-modal>
      <b-modal
        v-model="deviceReallocationModalShow"
        centered
        size="sm"
        no-close-on-esc
        no-close-on-backdrop
        :hide-header="true"
        modal-class="no-header-modal"
      >
        <b-card-text>
          <div class="device-realloccation-unit">
            <div class="justify-content-center text-center">
              {{ deviceReallocationMessage }}
            </div>
          </div>

          <div class="d-flex justify-content-center mb-64-1 mtt-50-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="deviceReallocation(device_list_object)"
            >
              {{ $t("unit.Yes") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-2"
              @click="
                () => {
                  this.deviceReallocationModalShow = false;
                }
              "
            >
              {{ $t("unit.No") }}
            </b-button>
          </div>
        </b-card-text>
      </b-modal>
      <AllocationPopUp
        v-if="isAllocationEnabled"
        :allocationData="unit"
        :component="c1"
        :status="status"
        :onClose="onClose"
        :changeInput="changeInput"
        :currentUnit="currentUnit"
        :isSelectedTab="isTabSelected"
        :refreshed="refreshed"
        :getAccountDetail="getAccountDetail"
      />
      <UnitDeviceSensorsPreview
        :device="deviceData"
        :isShowSensors="isShowSensors"
        :closeSensorsModal="closeSensorsModal"
        :unit="unit"
        v-if="deviceData"
      ></UnitDeviceSensorsPreview>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import formValidation from "@core/comp-functions/forms/form-validation";
import DeviceService from "@/libs/api/device-service";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import UnitDeviceSensorsPreview from "@/views/Unit/UnitDeviceSensorsPreview.vue";
import LocationPicker from "@/layouts/components/LocationPicker.vue";

import {
  LMap,
  LTileLayer,
  LMarker,
  LCircle,
  LPolygon,
  LIcon
} from "vue2-leaflet";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";
import { GeoSearchControl } from "leaflet-geosearch";
import { Icon } from "leaflet";
import flatPickr from "vue-flatpickr-component";
import DateTimePicker from "../DynamicComponents/DateWithTimePicker.vue";

import AllocationPopUp from "./AllocationPopUp.vue";
import { mapState } from "vuex";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import RuleService from "@/libs/api/rule-service";
import Loader from "@/layouts/components/Loader.vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BBadge,
  BCardText,
  BTab,
  BTabs,
  BModal,
  BAvatar,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import { required } from "@validations";
import AccountService from "@/libs/api/account-service";
import UnitService from "@/libs/api/unit-service";
import image from "../../assets/images/pages/avatar.svg";
import History from "@/layouts/components/History.vue";
import * as moment from "moment";
import validators from "@/utils/validators";
import constants from "@/utils/constants";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Ripple from "vue-ripple-directive";
import FileService from "@/libs/api/file-service";
import iconView from "../../views/icons-view/iconView.vue";
import draggable from "vuedraggable";
import DeviceSensorPreviewModal from "@/views/DeviceOnboarding/DeviceSensorPreviewModal.vue";
import DragDrop from "@/assets/images/icons/drag-drop.svg";
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "UnitCreationStepOneData",
  components: {
    Vue,
    DeviceSensorPreviewModal,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BCardCode,
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPolygon,
    LIcon,
    BButton,
    BCard,
    flatPickr,
    BDropdown,
    BDropdownItem,
    BBadge,
    BCardText,
    History,
    Treeselect,
    BTab,
    BTabs,
    AllocationPopUp,
    VueCropper,
    BModal,
    BSpinner,
    iconView,
    BAvatar,
    TagMultiSelect,
    draggable,
    DragDrop,
    UnitDeviceSensorsPreview,
    DateTimePicker,
    LocationPicker
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      coordinates: {},
      deviceDetails: {},
      device_list_object: null,
      id: null,
      allocate_to_id:
        this.$route.query.allocate_to || localStorage.getItem("ACCOUNT_ID"),
      unit_driver_id: null,
      downloadUrl: process.env.VUE_APP_FILE_URL,
      device_list: [],
      name: null,
      status: "ACTIVE",
      labels: [],
      attached_unit_to_id: null,
      showLoading: false,
      showLoadingClick: false,
      initialUnit: null,
      unitReAllocateTo: [],
      attachedUnitTo: [],
      deviceListOption: [],
      unitDriver: [],
      unit_icon: null,
      statusOption: [
        {
          label: this.$t("unit.StatusList.ACTIVE"),
          key: "ACTIVE"
        },
        {
          label: this.$t("unit.StatusList.INACTIVE"),
          key: "INACTIVE"
        },
        {
          label: this.$t("unit.StatusList.DAMAGED"),
          key: "DAMAGED"
        },
        {
          label: this.$t("unit.StatusList.BLOCKED"),
          key: "BLOCKED"
        }
      ],
      unitIcon: null,
      img: image,
      required,
      refFormObserver,
      bounds: null,
      getValidationState,
      latLng: latLng(0, 0),
      deviceData: {},
      zoom: 10,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159],
      mainProps: {
        blank: true,
        blankColor: "#777",
        width: 75,
        height: 75,
        class: "m1"
      },
      start_date: this.formattedDateTime(
        new Date(),
        constants.DATE_TIME_FORMAT
      ),
      end_date: null,
      disabledDate: this.DateTimeConvert(),
      endDateDisabled: null,
      filter_start_date: null,
      filter_end_date: null,
      allocationHistory: [],
      currentPage: 1,
      totalUnit: 0,
      isEnabled: null,
      perPage: 10,
      minTime: null,
      isFetchingOptions: false,
      c1: null,
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      files: "",
      unit_icon1: null,
      modalShow: false,
      iconSize: 40,
      file_name_url: "",
      deviceListModalShow: false,
      filter: "",
      isDeviceSelected: false,
      isShowSensors: false,
      filterAccount: "",
      deviceReallocationModalShow: false,
      deviceReallocationTitle: "",
      deviceReallocationMessage: "",
      isAllocationEnabled: false,
      isConsumer:
        localStorage.getItem("USER_ACCOUNT_TYPE") === "CONSUMER" ? true : false
    };
  },
  setup() {
    const resolveFilterBadgeColor = (filter) => {
      filter = filter && filter.toUpperCase();
      const status = {
        ACTIVE: "light-success",
        INACTIVE: "light-warning",
        DAMAGED: "light-danger",
        BLOCKED: "light-secondary"
      };

      const selectedStatus =
        filter && status[filter] ? status[filter] : status["ACTIVE"];

      return selectedStatus;
    };
    return {
      resolveFilterBadgeColor
    };
  },
  mounted() {
    this.getChildAccounts();
    if (this.$route.name === "view-unit") {
      this.isEnabled = true;
    } else if (this.$route.name === "edit-unit") {
      this.isEnabled = false;
    }
    setTimeout(() => {
      const map = this.$refs.mymap && this.$refs.mymap.mapObject;

      if (this.unit && this.unit.latitude && this.unit.longitude && map) {
        map.fitBounds([this.unit.latitude, this.unit.longitude]);
        this.latLng = latLng(this.unit.latitude, this.unit.longitude);
      }
      if (this.unit && this.unit.allocate_to_id) {
        this.getAllOperators(this.unit.allocate_to_id);
      }
    }, 2000);
  },
  computed: {
    ...mapState(["user"]),
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  watch: {
    $route(to, from) {
      if (this.$route.name === "view-unit") {
        this.isEnabled = true;
      } else if (this.$route.name === "edit-unit") {
        this.isEnabled = false;
      }
      this.$refs.mymap.mapObject.dragging._enabled = false;
    },

    device_list_object(val) {
      this.deviceListOption &&
        this.deviceListOption.length &&
        this.deviceListOption.map((d) => {
          let date = d["last_messages.message_time"]
            ? this.DateTimeConvert(d["last_messages.message_time"])
            : "";

          if (!date) {
            date = d.message_time ? this.DateTimeConvert(d.message_time) : "";
          }
          if (d.id == val) {
            this.isDeviceSelected = true;
            this.deviceDetails = {
              id: d.id,
              name: d.device_name,
              model_name: d.model_name || d["device_model.model_name"],
              identifier: d.identifier,
              status: d.status,
              labels: d.labels || [],
              date: date
            };
          }
        });
    },
    unit_icon(val) {
      this.changeInput({
        ...this.unit,
        unit_icon: val
      });
    },
    unit(val) {
      if (val && (this.isEnabled == true || this.isEnabled == false)) {
        this.name = this.unit.name;
        this.device_list = this.unit.device_list;
        this.status = this.unit.status || "ACTIVE";
        this.labels = this.unit.labels;
        this.attached_unit_to_id = this.unit.attached_unit_to_id
          ? this.unit.attached_unit_to_id
          : this.unit.pid;
        this.allocate_to_id = this.unit.allocate_to_id
          ? this.unit.allocate_to_id
          : this.unit["account.id"];
        this.unit_driver_id =
          this.unit && this.unit.unit_driver_id
            ? this.unit.unit_driver_id
            : this.unit && this.unit.operator && this.unit.operator.id;
        this.id = this.unit.id;
        this.latLng = [this.unit.latitude, this.unit.longitude];
        this.coordinates = {
          fenceType: "marker",
          latlngs: [{ lat: this.unit.latitude, lng: this.unit.longitude }]
        };
        this.unit_icon = this.unit.unit_icon;
        this.file_name = this.unit.file_name;
        this.file_name_url = this.unit.file_name_url;
        this.start_date = this.unit.start_date
          ? this.unit.start_date
          : moment();
        if (this.unit.end_date) {
          this.end_date = this.unit.end_date;
        }

        if (this.$route.name === "view-unit") {
          this.$route.meta.breadcrumb = [
            ...constants.UNIT_ROUTE_META_BREADCRUMB,
            {
              text: this.unit.name,
              active: true
            }
          ];
        } else if (this.$route.name === "edit-unit") {
          this.$route.meta.breadcrumb = [
            ...constants.UNIT_ROUTE_META_BREADCRUMB,
            {
              text: this.unit.name,
              to: { name: "view-unit", params: { id: this.unit.id } }
            },
            {
              text: "Breadcrumb_Edit",
              active: true
            }
          ];
        }
      }
    },
    startIndex(val) {
      if (val == 1) {
        this.name = this.unit.name;
        this.device_list = this.unit.device_list;
        this.status = this.unit.status || "ACTIVE";
        this.labels = this.unit.labels;
        this.attached_unit_to_id = this.unit.attached_unit_to_id;
        this.allocate_to_id = this.unit.allocate_to_id
          ? this.unit.allocate_to_id
          : this.unit["account.id"];
        this.unit_driver_id =
          this.unit && this.unit.unit_driver_id
            ? this.unit.unit_driver_id
            : this.unit && this.unit.operator && this.unit.operator.id;
        this.id = this.unit.id;
        this.start_date = this.unit.start_date
          ? this.unit.start_date
          : moment().format();
        this.end_date = this.unit.end_date;
      }
    },
    name(val) {
      this.changeInput({
        ...this.unit,
        name: val
      });
    },
    device_list(val) {
      this.changeInput({
        ...this.unit,
        device_list: val
      });
      this.unit.device_list = val;
    },
    status(val) {
      this.changeInput({
        ...this.unit,
        status: val
      });
    },
    labels(val) {
      this.changeInput({
        ...this.unit,
        labels: val
      });
    },
    attached_unit_to_id(val) {
      this.changeInput({
        ...this.unit,
        attached_unit_to_id: val
      });
    },
    allocate_to_id(val) {
      this.changeInput({
        ...this.unit,
        allocate_to_id: val,
        ["account.id"]: val
      });
      this.unitDriver = [];
      this.unit_driver_id = null;

      if (val) {
        if (localStorage.getItem("USER_ACCOUNT_ROLE_NAME") != "Operator") {
          this.getAllOperators(val);
        }
      } else {
        this.changeInput({
          ...this.unit,
          unit_driver_id: null,
          ["user.id"]: null
        });
        this.unit_driver_id = null;
      }
    },
    unit_driver_id(val) {
      if (val) {
        this.changeInput({
          ...this.unit,
          unit_driver_id: val
        });
      }
    },
    start_date(val) {
      this.changeInput({
        ...this.unit,
        start_date: val ? val : this.DateTimeConvert(val)
      });
      this.endDateDisabled = val ? this.DateTimeConvert(val) : null;

      if (
        !val ||
        moment(val).format() <
          moment()
            .add(1, "days")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format()
      ) {
        this.minTime = moment().format("HH:mm");
      } else {
        this.minTime = moment()
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
          .format("HH:mm");
      }
    },
    end_date(val) {
      this.changeInput({
        ...this.unit,
        end_date: val
      });
      if (
        !val ||
        moment(val).format() <
          moment()
            .add(1, "days")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format()
      ) {
        this.minTime = moment().format("HH:mm");
      } else {
        this.minTime = moment()
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          })
          .format("HH:mm");
      }
    },
    coordinates: {
      handler(newValue, oldValue) {
        if (
          newValue &&
          newValue.latlngs &&
          newValue.latlngs.length &&
          newValue.latlngs[0] &&
          newValue.latlngs[0].lat
        ) {
          this.$emit("unitCoordsData", newValue);
          this.latLng = [newValue.latlngs[0].lat, newValue.latlngs[0].lng];
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    removeEventListener("scroll", (event) => {});
  },
  props: [
    "changeInput",
    "unit",
    "disabled",
    "startIndex",
    "currentUnit",
    "resetForm",
    "isSelectedTab",
    "refreshed",
    "isUnitOnlyAllocated",
    "isUnitOwner"
  ],
  methods: {
    DateTimeConvert(date) {
      let formattedDate = date ? date : new Date();
      return this.formattedDateTime(formattedDate, constants.DATE_TIME_FORMAT);
    },
    getAccountDetail() {},
    changeDate(e) {
      return this.DateTimeConvert(e);
    },
    devicePreview(device) {
      this.deviceData = device;
      this.isShowSensors = true;
    },
    clearStartDate(e) {
      e.preventDefault();
      this.$refs.flatpickerStartDate.fp.clear();
    },
    clearEndDate(e) {
      e.preventDefault();
      this.$refs.flatpickerEndDate.fp.clear();
    },
    search(filter) {
      if (filter == "" && this.isDeviceSelected) {
        this.isDeviceSelected = false;
      } else {
        this.getAlldeviceList(filter);
      }
    },
    changeInputVSelect(value) {
      if (value == null) {
        this.deviceListOption = [];
        this.getAlldeviceList();
      }
    },
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    closeSensorsModal() {
      this.isShowSensors = false;
    },
    async saveImage() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      let newValue = this.dataURLtoFile(this.cropedImage, "my-file");
      if (newValue) {
        this.showLoading = true;
        let data = new FormData();
        let file = newValue;

        data.append("name", "my-file");
        data.append("file", file);
        new FileService()
          .fileApi(data)
          .then((res) => {
            if (res && res.success) {
              this.unit_icon = res.code;
              this.modalShow = false;
            } else if (res && !res.success && res.err && res.err.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.err.name,
                  text: res.err.message,
                  icon: "EditIcon",
                  variant: "error"
                }
              });
            }
            this.showLoading = false;
          })
          .catch((err) => {
            this.showLoading = false;
            this.showModal = false;
          });
      } else {
        this.unitIcon = image;
      }
    },
    async cancelImage() {
      this.modalShow = false;
    },
    onFileSelect(e) {
      this.modalShow = true;
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
      this.mime_type = file.type;
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("unit.FileReaderNotSupported"),
            text: this.$t("unit.FileReaderNotSupported"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    onCloseDeviceModal() {
      this.deviceListModalShow = false;
    },
    handleMenuOpen() {
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu();
        const hasReachedEnd =
          menu.scrollHeight - menu.scrollTop <= menu.clientHeight * 0.25;
        menu.addEventListener("scroll", () => {
          if (hasReachedEnd) this.fetchOptions();
        });
      });
    },

    async fetchOptions() {
      if (this.isFetchingOptions) return;
      this.isFetchingOptions = true;

      let response = await new AccountService().getChildAccounts({
        isChild: true,
        filterAccount: filterAccount || undefined,
        page: this.currentPage,
        page_size: parseInt(this.perPage)
      });

      if (response && response.data) {
        this.unitReAllocateTo = response.data;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.isFetchingOptions = false;
    },

    minDate(start_date) {
      if (start_date == null) {
        return this.disabledDate;
      } else {
        start_date <= new Date() ? "" : this.disabledDate;
      }
    },
    async getDeviceRules(item) {
      try {
        this.showLoadingClick = true;
        let response = await new RuleService().getDeviceRules({
          unit_id: this.id,
          id: item.id,
          filter: "",
          page: 1,
          page_size: 5
        });
        if (response && response.data) {
          this.showLoadingClick = false;
          if (response.data.pagination.total_records == 0) {
            this.$router.push({
              name: "rules",
              query: { deviceId: item.id, unitId: this.id }
            });
          } else {
            this.$router.push({
              name: `event-list`,
              query: { deviceId: item.id, unitId: this.id }
            });
          }
        } else if (response && response.error && response.error.message) {
          this.showLoadingClick = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoadingClick = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    removeDevice(id) {
      let index = this.device_list.findIndex((d) => d.id == id);
      if (index > -1) {
        this.device_list.splice(index, 1);
      }
    },
    onClose(modalId) {
      this.$bvModal.hide(modalId);
    },
    async saveDeviceList() {
      if (this.deviceDetails && this.deviceDetails.id) {
        let response = await new UnitService().checkDeviceAllocated({
          device_id: this.deviceDetails.id
        });

        if (response && response.data) {
          if (!this.device_list.find((d) => d.id == this.deviceDetails.id)) {
            this.device_list.push(this.deviceDetails);
            this.isDeviceSelected = false;
            this.onCloseDeviceModal();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("unit.CantAllocateSameDevice"),
                text: this.$t("unit.CantAllocateSameDevice"),
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        } else if (response && response.error && response.error.message) {
          this.deviceReallocationModalShow = true;
          this.deviceReallocationTitle = response.error.title;
          this.deviceReallocationMessage = response.error.message;
        }
      }
    },
    showFun() {
      this.deviceListModalShow = true;
      this.device_list_object = null;
      this.getAlldeviceList();
    },
    showAllocation() {
      this.isAllocationEnabled = true;
      this.unitAllocationHistory();
      setTimeout(() => {
        this.$bvModal.show("unit-allocate");
      }, 500);
    },
    async unitAllocationHistory() {
      if (this.$route.params.id) {
        let response = await new UnitService().unitAllocationHistory({
          id: this.$route.params.id,
          start_date: this.convertDatePerTimezone(this.filter_start_date),
          end_date: this.convertDatePerTimezone(this.filter_end_date),
          page: 1,
          page_size: 1
        });

        if (response && response.data) {
          let allocateHostory = [];
          if (
            response.data.unitAllocationHistory &&
            response.data.unitAllocationHistory.length
          ) {
            response.data.unitAllocationHistory.map((h) => {
              let history = {
                account: h.account,
                start_date: this.DateTimeConvert(h.start_date),
                end_date: h.end_date ? this.DateTimeConvert(h.end_date) : "",
                unit_id: h.unit_id,
                isEditable: h.isEditable ? h.isEditable : "",
                isDeleteable: h.isDeleteable ? h.isDeleteable : ""
              };
              allocateHostory.push(history);
            });
          }

          this.allocationHistory = allocateHostory;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    },
    mapShown() {
      setTimeout(() => {
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.latLng = [position.coords.latitude, position.coords.longitude];
          },
          (error) => {
            console.log(error);
          }
        );
      }
      const provider = new OpenStreetMapProvider();
      const map = this.$refs.mymap.mapObject;
      const searchControl = new GeoSearchControl({
        provider,
        autoComplete: true,
        autoCompleteDelay: 250,
        showMarker: false
      });

      map.addControl(searchControl);
      map.on("geosearch/showlocation", this.geosearch);
    },
    geosearch(e) {
      this.latLng = [e.marker._latlng.lat, e.marker._latlng.lng];
    },
    showlocation(e) {
      this.latLng = [e.latlng.lat, e.latlng.lng];
    },
    updatelocation(latLng) {
      this.latLng = [latLng.lat, latLng.lng];
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    async getAlldeviceList(filter) {
      try {
        let response = await new DeviceService().deviceList({
          filter: filter || undefined,
          page: this.currentPage,
          excluded_id: this.device_list.map((d) => d.id)
        });
        if (response && response.data) {
          this.deviceListOption = response.data.devices;
          this.deviceListOption = [
            ...new Map(
              this.deviceListOption.map((item) => [item["id"], item])
            ).values()
          ];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getChildAccounts(filterAccount) {
      try {
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || undefined,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });

        if (response && response.data) {
          this.unitReAllocateTo = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    getAllDealersData(data) {
      const result = [];
      function recursive(data) {
        result.push({ id: data.id, name: data.name });
        if (data.children.length == 0) {
          return;
        }
        for (let i = 0; i < data.children.length; i++) {
          recursive(data.children[i]);
        }
      }
      recursive(data[0]);
      return result;
    },
    async getAllOperators(id) {
      try {
        let response = await new UnitService().getOperators({
          getAccountOperator: id
        });
        if (response && response.data) {
          this.unitDriver = response.data.list.map((u) => {
            return {
              id: u.id,
              title: u.name,
              value: u.id
            };
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    selectedIcon(icon) {
      this.unit.file_name = icon.path;
      this.unit.file_name_url = icon.url;
    },
    async updateUnit(id, e) {
      e.preventDefault();
      const me = this;
      try {
        if (JSON.stringify(this.unit) === JSON.stringify(this.currentUnit)) {
          return;
        }
        const isValidateLabels =
          this.unit.labels &&
          this.unit.labels.every(
            (item) => typeof item === "object" && item !== null
          );

        if (isValidateLabels) {
          const me = this;
          me.unit.labels =
            me.unit &&
            me.unit.labels &&
            me.unit.labels.map((label) => label && label.name);
          me.unit.labels =
            me.unit.labels &&
            me.unit.labels.filter((label) => label !== "" || label !== null);
        }
        this.unit.labels =
          me.unit.labels &&
          me.unit.labels.filter((label) => label !== "" || label !== null);
        let requestData = { id: this.$route.params.id };
        if (this.unit.name != this.currentUnit.name) {
          requestData.name = this.unit.name;
        }
        if (this.unit.status != this.currentUnit.status) {
          requestData.status = this.unit.status;
        }
        if (this.unit.labels != this.currentUnit.labels) {
          requestData.labels = this.unit.labels;
        }
        if (this.latLng[0] != this.currentUnit.latitude) {
          requestData.unit_lat = this.latLng[0];
        }
        if (this.latLng[1] != this.currentUnit.longitude) {
          requestData.unit_long = this.latLng[1];
        }

        if (this.unit.unit_icon != this.currentUnit.unit_icon) {
          requestData.unit_icon = this.unit_icon;
        }
        if (this.unit.file_name != this.currentUnit.file_name) {
          requestData.file_name = this.unit.file_name;
        }
        if (!this.handleValdition()) {
          requestData.device_list = this.unit.device_list.map((d) => d.id);
        }
        requestData.allocate_to_id = this.allocate_to_id;
        let response = await new UnitService().updateUnit(requestData);

        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.UpdatedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$router.push({ name: "unit-list" });
        } else if (response && response.error && response.error.message) {
          localStorage.setItem("error", JSON.stringify(response.error));
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        localStorage.setItem("error", JSON.stringify(err));
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    handleValdition() {
      this.unit.device_list.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      this.currentUnit.device_list.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      if (
        this.unit.device_list.length === this.currentUnit.device_list.length
      ) {
        for (let i = 0; i < this.unit.device_list.length; i++) {
          if (this.unit.device_list[i] !== this.currentUnit.device_list[i]) {
            return false;
          }
        }
        return true;
      } else {
        this.unit.device_list.sort(function (a, b) {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });
        this.currentUnit.device_list.sort(function (a, b) {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });
        const length1 = this.unit.device_list.length;
        const length2 = this.currentUnit.device_list.length;
        const length = length1 < length2 ? length2 : length1;
        for (let i = 0; i < length; i++) {
          if (this.unit.device_list[i] !== this.currentUnit.device_list[i]) {
            return false;
          }
        }
        return true;
      }
    },

    editUnit(props, isEditable) {
      this.isEnabled = !this.isEnabled;
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },

    isTabSelected() {
      this.onClose("is-unit-allocated");
    },
    handleTreeSelect() {
      this.changeInput({
        ...this.unit,
        unit_driver_id: null,
        ["user.id"]: null
      });
      this.unitDriver = [];
      this.unit_driver_id = null;
    },
    async deviceReallocation(data) {
      let requestData = {
        device_id: data
      };
      let response = await new UnitService().deviceReallocation(requestData);
      if (response && response.data) {
        this.deviceReallocationModalShow = false;
        if (!this.device_list.find((d) => d.id == this.deviceDetails.id)) {
          this.device_list.push(this.deviceDetails);
          this.isDeviceSelected = false;
          this.onCloseDeviceModal();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.CantAllocateSameDevice"),
              text: this.$t("unit.CantAllocateSameDevice"),
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.remove-350 .vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}

.map-outer {
  width: 100%;
}

.user {
  width: 140px;
  height: 140px;
  border-radius: 100%;
  border: 3px solid #2e7d32;
  position: relative;
}

.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.icon {
  position: absolute;
  top: 10px;
  right: 0;
  background: #e2e2e2;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  color: #0000ff;
  font-size: 14px;
  cursor: pointer;
}

.custom-file-label {
  cursor: pointer !important;
}

.dark-layout .vue-treeselect__control {
  background-color: #283046;
  border-color: #404656;
  color: #e2e2e2;
}

.dark-layout .vue-treeselect__single-value {
  color: aliceblue !important;
}

// .dark-layout .vue-treeselect__option-arrow-container,
// .dark-layout .vue-treeselect__option--highlight {
//   background-color: #283046 !important;
// }

// .vue-treeselect__option-arrow-container,
// .vue-treeselect__option--highlight {
//   background-color: #e2e2e2 !important;
// }

.dark-layout .vue-treeselect__menu {
  background-color: #283046;
  border-color: #404656;
  color: #e2e2e2;
}

.dark-layout .flat-picker-icon {
  border-right: 1px solid #404656;
  border-top: 1px solid #404656;
  border-bottom: 1px solid #404656;
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  padding: 0.438rem 1rem;
}

.badge-cust {
  height: 20px;
  display: inline-block;
  border-radius: 17px;
  margin-right: 24px;
  background: var(--rgb_gray_dark);
  color: var(--red2);
  padding: 4px 9px !important;
}

.flat-picker-date {
  display: flex;
  justify-content: center;
  align-items: center;

  .form-control {
    border-radius: 0.357rem 0rem 0rem 0.357rem;
  }
}

.flat-picker-icon {
  border-right: 1px solid var(--gray4);
  border-top: 1px solid var(--gray4);
  border-bottom: 1px solid var(--gray4);
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  padding: 0.438rem 1rem;
}

.cust-card {
  border-top: 1px solid $gray8;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  min-height: 190px;
}

.device-name {
  font-size: $px_15;
  color: $body-color;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: $px_10;
}

.modal-version {
  font-size: 13px;
  color: $body-color;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: $px_10;
}

.card-number {
  font-size: 12px;
  color: $body-color;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: $px_10;
}

.add-new {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $percent_100;
  width: $percent_100;
  flex-direction: column;
  min-height: 150px;
  cursor: pointer;
}

.unit12 {
  .b-avatar {
    .b-avatar-img {
      img {
        margin-bottom: 20px;
        position: absolute;
        padding: 5px;
      }
    }
  }
}

.dark-layout {
  .dark-bg-card {
    background: var(--dark-bg);
  }
}

@media only screen and (max-width: 820px) {
  .device-h {
    .modal-body {
      min-height: 8vh;
      max-height: 72vh;
      overflow: auto;
    }
  }

  .submitbtn {
    padding-left: 17px;
    padding-right: 17px;
  }
}

@media only screen and (max-width: 640px) {
  .unit-card-p {
    padding: 16px !important;
  }
}

.vue-treeselect--has-value {
  .vue-treeselect__control {
    border-color: var(--success) !important;

    .vue-treeselect__value-container {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328c76f' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: calc(0.725em + 0.438rem) calc(0.725em + 0.438rem);
      min-width: 46px;
      justify-content: flex-end;
      position: relative;
      background-position: 100% 9px;
    }
  }
}
</style>
<style lang="scss" scoped>
.unit-create-scroll {
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
}
.unit-creation-scroll {
  height: calc(100vh - 376px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style lang="scss">
.new-date-picker-25 {
  .daterangepicker.ltr.show-calendar.single.openscenter.linked {
    left: 0 !important;
    transform: translate(0%) !important;
  }
}
</style>
